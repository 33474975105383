<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="用户昵称：">
                    <el-input size="default" v-model.trim="formInline.nickname" maxlength="60"  clearable placeholder="用户昵称" @change="search" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input size="default" v-model.trim="formInline.mobile" maxlength="60"  clearable placeholder="手机号" @change="search" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="创建时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="isShowBtn('userManage','用户管理','Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="addAdmin" type="primary" icon="Plus" v-show="isShowBtn('userManage','用户管理','Create')">新增</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="exportDataBackend" type="primary">导出</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table  :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <el-table-column min-width="70" prop="avatar" label="用户头像">
                    <template #default="scope">
                        <img  :src="scope.row.avatar ? scope.row.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg" >
                    </template>
                </el-table-column>
                <el-table-column min-width="110" prop="username" label="用户名"></el-table-column>
                <el-table-column min-width="110" prop="nickname" label="用户昵称"></el-table-column>
                <el-table-column min-width="100" prop="mobile" label="手机号"></el-table-column>
                <el-table-column min-width="100" prop="balance" label="余额"></el-table-column>
                <el-table-column min-width="100" prop="rest_consumption" label="算力余额"></el-table-column>
                <el-table-column min-width="100" prop="use_consumption" label="算力消耗"></el-table-column>
                <el-table-column min-width="100" label="状态">
                    <template #default="scope">
                        <el-tag v-if="scope.row.is_active">正常</el-tag>
                        <el-tag v-else type="danger">禁用</el-tag>
<!--                        <el-switch-->
<!--                            v-model="scope.row.is_active"-->
<!--                            active-color="#13ce66"-->
<!--                            inactive-color="#ff4949"-->
<!--                            @change="changeStatus(scope.row)">-->
<!--                        </el-switch>-->
                    </template>
                </el-table-column>
                <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
<!--                <el-table-column label="系统赠送" width="180">-->
<!--                  <el-button type="primary" size="mini" @click="openGiftForm(scope.row)">余额</el-button>-->
<!--                  <el-button type="primary" size="mini" @click="openGiftForm(scope.row)">算力</el-button>-->
<!--                </el-table-column>-->
                <el-table-column label="系统赠送" width="180">
                  <template #default="scope">
                    <el-button type="primary"  @click="openGiftForm(scope.row)">余额</el-button>
                    <el-button type="primary"  @click="openPowerForm(scope.row)">算力</el-button>
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="isShowBtn('userManage','用户管理','Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="isShowBtn('userManage','用户管理','Retrieve')">详情</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="isShowBtn('userManage','用户管理','Delete')">删除</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'disable')" v-show="isShowBtn('userManage','用户管理','Update')">
                            <span v-if="scope.row.is_active">禁用</span>
                            <span v-else>启用</span>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-user ref="addUserFlag" @refreshData="getData"></add-user>
        <user-detail ref="userDetailFlag"></user-detail>
        <div>
            <!-- 赠送余额的表单 -->
            <el-dialog v-model="dialogVisible" title="赠送余额">
              <el-form :model="giftForm"  :rules="rules" label-position="right" ref="giftFormRef" label-width="auto">
                <el-form-item label="用户ID" >
                  <el-input v-model="giftForm.id" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="用户名">
                  <el-input v-model="giftForm.username" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="用户姓名">
                  <el-input v-model="giftForm.name" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="用户昵称" >
                  <el-input v-model="giftForm.nickname" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="用户手机号" >
                  <el-input v-model="giftForm.mobile" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="赠送金额" >
<!--                  <el-input v-model="giftForm.amount" autocomplete="off"></el-input>-->
                  <el-input
                      clearable
                      placeholder="金额最多两位小数"
                      v-model="giftForm.amount"
                      @input="validateAmount"
                      autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注：">
                  <el-input size="default" v-model="giftForm.remark" maxlength="60"  clearable placeholder="备注" @change="search"></el-input>
                </el-form-item>
              </el-form>
              <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitGift">确定</el-button>
              </template>
            </el-dialog>
        </div>
        <div>
          <!-- 赠送算力的表单 -->
          <el-dialog v-model="dialogPower" title="赠送算力">
            <el-form :model="powerForm"  label-position="right" label-width="auto">
              <el-form-item label="用户ID" >
                <el-input v-model="powerForm.id" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="用户名">
                <el-input v-model="powerForm.username" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="用户姓名">
                <el-input v-model="powerForm.name" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="用户昵称" >
                <el-input v-model="powerForm.nickname" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="用户手机号" >
                <el-input v-model="powerForm.mobile" autocomplete="off" disabled></el-input>
              </el-form-item>
              <el-form-item label="算力信息">
                <el-select v-model="selectedItemId" placeholder="请选择一个套餐" :disabled="loading">
                  <el-option
                      v-for="item in items"
                      :key="item.id"
                      :label="`${item.name} - ￥${item.price} - 算力${item.computation}`"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>

              <!-- 展示选中项目的信息 -->
<!--              <div v-if="selectedItem">-->
<!--                <h3>选中的项目详情</h3>-->
<!--                <p>名称: {{ selectedItem.name }}</p>-->
<!--                <p>价格: ￥{{ selectedItem.price }}</p>-->
<!--                <p>计算力: {{ selectedItem.computation }}</p>-->
<!--                <p>库存: {{ selectedItem.stock }}</p>-->
<!--                <p>描述: {{ selectedItem.desc_detail }}</p>-->
<!--              </div>-->

              <el-form-item label="备注：">
                <el-input size="default" v-model="powerForm.remark" maxlength="60"  clearable placeholder="备注" @change="search"></el-input>
              </el-form-item>
            </el-form>
            <template #footer>
              <el-button @click="handleClose" :loading="loadingpowerSave">取消</el-button>
              <el-button type="primary" @click="submitPower">确定</el-button>
            </template>
          </el-dialog>
        </div>
      </div>
</template>
<script>
    import addUser from "./components/addUser";
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight} from "@/utils/util";
    import {
      UsersUsers,
      UsersUsersDelete,
      UsersUsersdisableEdit,
      UsersUsersExportexecl,
      systemaddbalance,
      systemaddpower,
      mallGoodstypeAdd, systemtaocan
    } from '@/api/api'
    import UserDetail from "./components/userDetail";
    export default {
        components:{
            UserDetail,
            Pagination,
            addUser
        },
        name:'userManage',
        data() {
            return {
                items: [],
                selectedItemId: '',
                loading: false, // 标记是否正在加载数据
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                statusList:[
                    {id:1,name:'正常'},
                    {id:0,name:'禁用'}
                ],
                identityList:[
                    {id:0,name:'普通用户'},
                    {id:1,name:'会员'},
                ],
                timers:[],
                tableData:[],
                loadingSave:false,
                dialogVisible: false,
                dialogPower:false,
                loadingpowerSave:false,
                giftForm:{
                  id:'',
                  amount:'5',
                  remark:'系统赠送',
                },
                powerForm:{
                  id:'',
                  sku_id:'',
                  remark:'系统赠送',
                },
                rules: {
                  amount: [
                    { required: true, message: '请输入金额', trigger: 'blur' },
                    { pattern: /^(?!\.?$)\d{1,}(\.\d{1,2})?$/, message: '请输入有效的金额数字，最多两位小数', trigger: 'blur' }
                  ]
                }
            }
        },

        methods:{
            validateAmount(value) {
              if (!value) return;
              const regex = /^\d*(\.\d{0,2})?$/;
              if (!regex.test(value)) {
                this.giftForm.amount = value.substring(0, value.length - 1);
              } else {
                this.giftForm.amount = value;
              }
            },
            openGiftForm(row) {
              this.dialogVisible = true;
              // 打开表单，并可能需要将当前行的数据设置到表单中
              // 例如，如果需要将用户ID设置到表单中
              this.giftForm.id = row.id;
              this.giftForm.username = row.username;
              this.giftForm.name=row.name;
              this.giftForm.nickname = row.nickname;
              this.giftForm.mobile = row.mobile;
            },
            submitGift() {
              // 提交赠送余额的逻辑
              // 这里可以调用API或者执行其他操作
              this.$refs.giftFormRef.validate((valid) => {
                if (valid) {
                  // 如果验证通过，执行提交逻辑
                  console.log('赠送金额:', this.giftForm.amount);
                  // 这里可以调用API或者执行其他操作
                  let param = {
                    ...this.giftForm
                  }
                  systemaddbalance(param).then(res=>{
                    // this.loadingSave=false
                    if(res.code ==2000) {
                      this.$message.success(res.msg)
                      this.handleClose()
                      this.$emit('refreshData')
                      this.getData()
                    } else {
                      this.$message.warning(res.msg)
                    }
                  })
                  this.dialogVisible = false;
                } else {
                  // 如果验证失败，阻止表单提交
                  console.log('验证失败');
                  // return false;
                }
              });
            },
            handleClose() {
              this.dialogVisible=false
              this.dialogPower=false
              this.loadingSave=false
              this.loadingpowerSave=false
              this.$emit('refreshData')
            },
            fetchtaocanData() {
              this.loading = true;
              systemtaocan({}).then(res => {
                if (res.code === 2000 && res.data) {
                  this.items = res.data.data;
                } else {
                  console.error('获取数据失败:', res.msg);
                }
                this.loading = false;
              }).catch(error => {
                console.error('请求失败:', error);
                this.loading = false;
              });
            },
            openPowerForm(row) {
              this.dialogPower = true;
              this.fetchtaocanData();
              // 打开表单，并可能需要将当前行的数据设置到表单中
              // 例如，如果需要将用户ID设置到表单中
              this.powerForm.id = row.id;
              this.powerForm.username = row.username;
              this.powerForm.name=row.name;
              this.powerForm.nickname = row.nickname;
              this.powerForm.mobile = row.mobile;
            },
            submitPower() {
              // 提交赠送余额的逻辑
                // 这里可以调用API或者执行其他操作
              if (this.selectedItemId) {
                this.powerForm.sku_id=this.selectedItemId
                let param = {
                  ...this.powerForm
                }
                systemaddpower(param).then(res=>{
                  // this.loadingSave=false
                  if(res.code ==2000) {
                    this.$message.success(res.msg)
                    this.handleClose()
                    this.$emit('refreshData')
                    this.getData()
                  } else {
                    this.$message.warning(res.msg)
                  }
                })
                this.dialogPower = false;
              } else {
                this.$message.error('请选择一个算力套餐');
              }
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            changeStatus(row) {
                // console.log(row,'row----')
            },
            addAdmin() {
                this.$refs.addUserFlag.addUserFn(null,'新增')
            },
            handleEdit(row,flag) {
                if(flag=='edit') {
                    this.$refs.addUserFlag.addUserFn(row,'编辑')
                }
                else if(flag=='detail') {
                    this.$refs.userDetailFlag.addUserFn(row,'详情')
                }
                else if(flag=='disable'){
                    let vm = this
                    UsersUsersdisableEdit({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('您确定要删除选中的数据吗？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        UsersUsersDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.getData()
                }
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFileURL(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            exportDataBackend() {
                var params = {
                    page: 1,
                    limit: 9999,
                }
                UsersUsersExportexecl(params).then(res => {
                     if(res.code ==2000) {
                         this.downloadFileURL(res.data.data)
                         //this.$message.warning(res.data.data)
                     }
                 })
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                UsersUsers(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
				this.$nextTick(() => {
				    this.getTheTableHeight()
				})
			},
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            }

        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
              this.getTheTableHeight()
            })
        },

        unmounted() {
             // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
    }
</script>
